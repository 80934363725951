[
    {
        "name": "ignStyleBase",
        "import": "",
        "style": {
            "version": 8,
            "sources": {
                "simple-tiles": {
                    "type": "raster",
                    "maxzoom": 16,
                    "tileSize": 256,
                    "attribution": "Mapa del <a href=\"http://www.ign.gob.ar\">Instituto Geográfico Nacional</a>, capa de calles por colaboradores de &copy; <a href=\"http://openstreetmap.org\">OpenStreetMap</a>",
                    "scheme": "tms",
                    "tiles": [
                        "https://wms.ign.gob.ar/geoserver/gwc/service/tms/1.0.0/capabaseargenmap@EPSG%3A3857@png/{z}/{x}/{y}.png"
                    ]
                }
            },
            "layers": [
                {
                    "id": "simple-tiles",
                    "type": "raster",
                    "source": "simple-tiles",
                    "minzoom": 0,
                    "maxzoom": 20
                }
            ],
            "glyphs": "https://fonts.openmaptiles.org/{fontstack}/{range}.pbf",
            "sprite": "https://maputnik.github.io/osm-liberty/sprites/osm-liberty"
        }
    },
    {
        "name": "ignStyleGrey",
        "import": "",
        "style": {
            "version": 8,
            "sources": {
                "simple-tiles": {
                    "type": "raster",
                    "maxzoom": 16,
                    "tileSize": 256,
                    "attribution": "Mapa del <a href=\"http://www.ign.gob.ar\">Instituto Geográfico Nacional</a>, capa de calles por colaboradores de &copy; <a href=\"http://openstreetmap.org\">OpenStreetMap</a>",
                    "scheme": "tms",
                    "tiles": [
                        "https://wms.ign.gob.ar/geoserver/gwc/service/tms/1.0.0/mapabase_gris@EPSG%3A3857@png/{z}/{x}/{y}.png"
                    ]
                }
            },
            "layers": [
                {
                    "id": "simple-tiles",
                    "type": "raster",
                    "source": "simple-tiles",
                    "minzoom": 0,
                    "maxzoom": 20
                }
            ]
        }
    },
    {
        "name": "googleSatellite",
        "import": "",
        "style": {
            "version": 8,
            "sources": {
                "simple-tiles": {
                    "type": "raster",
                    "tileSize": 256,
                    "maxzoom": 18,
                    "attribution": "Imagery ©2021 TerraMetrics, Map data ©2021 GeoBasis-DE/BKG (©2009), Google, Inst Geogr. Naciona",
                    "tiles": [
                        "https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}&s=Ga"
                    ]
                }
            },
            "layers": [
                {
                    "id": "simple-tiles",
                    "type": "raster",
                    "source": "simple-tiles",
                    "minzoom": 0
                }
            ],
            "glyphs": "https://fonts.openmaptiles.org/{fontstack}/{range}.pbf",
            "sprite": "https://maputnik.github.io/osm-liberty/sprites/osm-liberty"
        }
    },
    {
        "name": "esriStyleSatellite",
        "import": "",
        "style": {
            "version": 8,
            "sources": {
                "simple-tiles": {
                    "type": "raster",
                    "tileSize": 256,
                    "maxzoom": 17,
                    "attribution": "Source: Esri, DigitalGlobe, GeoEye, Earthstar Geographics, CNES/Airbus DS, USDA, USGS, AeroGRID, IGN, and the GIS User Community",
                    "tiles": [
                        "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                    ]
                }
            },
            "layers": [
                {
                    "id": "simple-tiles",
                    "type": "raster",
                    "source": "simple-tiles",
                    "minzoom": 0
                }
            ],
            "glyphs": "https://fonts.openmaptiles.org/{fontstack}/{range}.pbf",
            "sprite": "https://maputnik.github.io/osm-liberty/sprites/osm-liberty"
        }
    },

    {
        "name": "ignStyleDark",
        "import": "",
        "style": {
            "version": 8,
            "sources": {
                "simple-tiles": {
                    "type": "raster",
                    "maxzoom": 16,
                    "tileSize": 256,
                    "attribution": "Mapa del <a href=\"http://www.ign.gob.ar\">Instituto Geográfico Nacional</a>, capa de calles por colaboradores de &copy; <a href=\"http://openstreetmap.org\">OpenStreetMap</a>",
                    "scheme": "tms",
                    "tiles": [
                        "https://wms.ign.gob.ar/geoserver/gwc/service/tms/1.0.0/argenmap_oscuro@EPSG%3A3857@png/{z}/{x}/{y}.png"
                    ]
                }
            },
            "layers": [
                {
                    "id": "simple-tiles",
                    "type": "raster",
                    "source": "simple-tiles",
                    "minzoom": 0,
                    "maxzoom": 20
                }
            ],
            "glyphs": "https://fonts.openmaptiles.org/{fontstack}/{range}.pbf",
            "sprite": "https://maputnik.github.io/osm-liberty/sprites/osm-liberty"
        }
    },
    {
        "name": "hereStyle",
        "import": "",
        "style": {
            "version": 8,
            "glyphs": "https://demotiles.maplibre.org/font/{fontstack}/{range}.pbf",
            "sources": {
                "raster-tiles": {
                    "type": "raster",
                    "tileSize": 512,
                    "attribution": "Map Tiles &copy $Date().getFullYear() <a href=\"http://developer.here.com\">HERE</a>",
                    "tiles": [
                        "https://1.base.maps.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png?lg=spa&app_id=jejhBr3f7xuxzOjxs6Xr&app_code=vK_udKQVKFKI6elL-HrN2g",
                        "https://2.base.maps.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png?lg=spa&app_id=jejhBr3f7xuxzOjxs6Xr&app_code=vK_udKQVKFKI6elL-HrN2g",
                        "https://3.base.maps.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png?lg=spa&app_id=jejhBr3f7xuxzOjxs6Xr&app_code=vK_udKQVKFKI6elL-HrN2g",
                        "https://4.base.maps.api.here.com/maptile/2.1/maptile/newest/normal.day/{z}/{x}/{y}/512/png?lg=spa&app_id=jejhBr3f7xuxzOjxs6Xr&app_code=vK_udKQVKFKI6elL-HrN2g"
                    ]
                }
            },
            "layers": [
                {
                    "id": "simple-tiles",
                    "type": "raster",
                    "source": "raster-tiles",
                    "minzoom": 0,
                    "maxzoom": 22
                }
            ]
        }
    },
    {
        "name": "osmStyle",
        "import": "https://icv.netlify.app/osm.json",
        "style": {
        }
    },
    {
        "name": "esriStyle",
        "import": "https://icv.netlify.app/esri.json",
        "style": {
        }
    },
    {
        "name": "esriNightStyle",
        "import": "https://icv.netlify.app/esriNight.json",
        "style": {
        }
    },
    {
        "name": "esriDarkCanvas",
        "import": "https://icv.netlify.app/esriDarkCanvas.json",
        "style": {
        }
    },
    {
        "name": "esriLightCanvas",
        "import": "https://icv.netlify.app/esriLightCanvas.json",
        "style": {
        }
    }
]
