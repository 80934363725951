[
   {
      "description": "NDVI 16 días - Terra/MODIS",
      "name": "ndvi16",
      "source": {
         "type": "raster",
         "tiles": [
            "https://vectortiles.000webhostapp.com/MOD_NDVI_16/{z}/{x}/{y}.png"
         ],
         "minzoom": 3,
         "maxzoom": 3,
         "tileSize": 256
      },
      "layer": {
         "id": "ndvi16",
         "type": "raster",
         "source": "ndvi16",
         "paint": {
            "raster-opacity": 0.8
         }
      }
   },
   {
      "description": "Peligro de Incendios Forestales",
      "name": "fwi",
      "source": {
         "type": "raster",
         "tiles": [
            "https://vectortiles.000webhostapp.com/gwis/{z}/{x}/{y}.png"
         ],
         "maxzoom": 4
      },
      "layer": {
         "id": "fwi",
         "type": "raster",
         "source": "fwi",
         "paint": {
            "raster-opacity": 0.3
         }
      }
   },
   {
      "description": "Elevación",
      "name": "hillshade",
      "source": {
         "type": "raster-dem",
         "tiles": [
            "https://vectortiles.000webhostapp.com/Hillshade_IGN_250m/{z}/{x}/{y}.png"
         ],
         "maxzoom": 9
      },
      "layer": {
         "id": "hillshade",
         "type": "hillshade",
         "source": "hillshade"
      }
   },
   {
      "description": "Límite Provincial",
      "name": "Limiteprovincial_area",
      "source": {
         "type": "vector",
         "maxzoom": 12,
         "tiles": [
            "https://vectortiles.000webhostapp.com/Limite-provincial_area/{z}/{x}/{y}.pbf"
         ]
      },
      "layer": {
         "id": "Limiteprovincial_area",
         "type": "line",
         "source": "Limiteprovincial_area",
         "source-layer": "Limiteprovincial_area",
         "layout": {
            "visibility": "none"
         },
         "paint": {
            "line-color": "rgba(0, 0, 0, .9)",
            "line-width": 1,
            "line-dasharray": [
               2,
               2
            ]
         }
      }
   },
   {
      "description": "Caminos",
      "name": "Vialidad_lineas",
      "source": {
         "type": "vector",
         "maxzoom": 10,
         "tiles": [
            "https://vectortiles.000webhostapp.com/Vialidad_lineas/{z}/{x}/{y}.pbf"
         ]
      },
      "layer": {
         "id": "Vialidad_lineas",
         "type": "line",
         "source": "Vialidad_lineas",
         "source-layer": "Vialidad_lineas",
         "layout": {
            "visibility": "none"
         },
         "paint": {
            "line-color": "rgba(75, 50, 50, 1)",
            "line-width": 2
         }
      }
   },
   {
      "description": "Cursos de agua",
      "name": "Hidrografia_lineas",
      "source": {
         "type": "vector",
         "maxzoom": 10,
         "tiles": [
            "https://vectortiles.000webhostapp.com/Hidrografia_lineas/{z}/{x}/{y}.pbf"
         ]
      },
      "layer": {
         "id": "Hidrografia_lineas",
         "type": "line",
         "source": "Hidrografia_lineas",
         "source-layer": "Hidrografia_lineas",
         "layout": {
            "visibility": "none"
         },
         "paint": {
            "line-color": "rgba(75, 100, 255, 1)",
            "line-width": 1
         }
      }
   },
   {
      "description": "Cuerpos de agua",
      "name": "Hidrografia_areas",
      "source": {
         "type": "vector",
         "maxzoom": 10,
         "tiles": [
            "https://vectortiles.000webhostapp.com/Hidrografia_areas/{z}/{x}/{y}.pbf"
         ]
      },
      "layer": {
         "id": "Hidrografia_areas",
         "type": "fill",
         "source": "Hidrografia_areas",
         "source-layer": "Hidrografia_areas",
         "layout": {
            "visibility": "none"
         },
         "paint": {
            "fill-color": "rgba(0, 0, 255, 1)",
            "fill-opacity": 0.5
         }
      }
   },
   {
      "description": "Hidrografia_puntos",
      "name": "Agua",
      "source": {
         "type": "geojson",
         "data": "Hidrografia_puntos.json"
      },
      "layer": {
         "id": "Agua",
         "type": "symbol",
         "source": "Agua",
         "layout": {
            "icon-image": "water_11",
            "icon-size": 1,
            "icon-allow-overlap": true
         },
         "paint": {
            "text-color": "white"
         }
      }
   },
   {
      "description": "Bomberos-SPMF_puntos",
      "name": "Bomberos",
      "source": {
         "type": "geojson",
         "data": "Bomberos-SPMF_puntos.json"
      },
      "layer": {
         "id": "Bomberos",
         "type": "symbol",
         "source": "Bomberos",
         "layout": {
            "icon-image": "fire-station_11",
            "icon-size": 1,
            "icon-allow-overlap": true
         },
         "paint": {
            "text-color": "white"
         }
      }
   },
   {
      "description": "Transporte-Aereo_puntos",
      "name": "Transporte-Aereo",
      "source": {
         "type": "geojson",
         "data": "Transporte-Aereo_puntos.json"
      },
      "layer": {
         "id": "Transporte-Aereo",
         "type": "symbol",
         "source": "Transporte-Aereo",
         "layout": {
            "icon-image": [
               "match", ["get", "Tipo"],
               "Aeropuerto", "airport_11",
               "Helipuerto", "heliport_11",
               "Aeródromo", "airfield_11",
               "airfield_11"
            ],
            "icon-size": 1,
            "icon-allow-overlap": true
         },
         "paint": {
            "text-color": "white"
         }
      }
   },
   {
      "description": "Inventario-Bosque_areas",
      "name": "Inventario-Bosque_areas",
      "source": {
         "type": "vector",
         "maxzoom": 10,
         "tiles": [
            "https://vectortiles.000webhostapp.com/Inventario-Bosque_areas/{z}/{x}/{y}.pbf"
         ]
      },
      "layer": {
         "id": "Inventario-Bosque_areas",
         "type": "fill",
         "source": "Inventario-Bosque_areas",
         "source-layer": "InventarioBosque_areas",
         "layout": {
            "visibility": "none"
         },
         "paint": {
            "fill-color": "rgba(0, 255, 0, 1)",
            "fill-opacity": 0.3,
            "fill-outline-color": "green"
         }
      }
   },
   {
      "description": "Vegetacion-Unidades_areas",
      "name": "Vegetacion-Unidades_areas",
      "source": {
         "type": "vector",
         "maxzoom": 10,
         "tiles": [
            "https://vectortiles.000webhostapp.com/Vegetacion-Unidades_areas/{z}/{x}/{y}.pbf"
         ]
      },
      "layer": {
         "id": "Vegetacion-Unidades_areas",
         "type": "fill",
         "source": "Vegetacion-Unidades_areas",
         "source-layer": "VegetacionUnidades_areas",
         "layout": {
            "visibility": "none"
         },
         "paint": {
            "fill-color": "rgba(0, 155, 0, 1)",
            "fill-opacity": 0.3,
            "fill-outline-color": "orange"
         }
      }
   },
   {
      "description": "Focos de calor SNPP_24HRS",
      "name": "SNPP_24HRS",
      "source": {
         "type": "geojson",
         "data": "https://vectortiles.000webhostapp.com/data/fires_snpp_24hrs.json"
      },
      "layer": {
         "id": "SNPP_24HRS",
         "type": "circle",
         "source": "SNPP_24HRS",
         "layout": {
            "visibility": "none"
         },
         "paint": {
            "circle-radius": [
               "interpolate", [ "linear" ], [ "zoom" ], 4, [ "/", [ "number", [ "get", "brightness" ], 0 ], 80 ],
               15, [ "/", [ "number", [ "get", "brightness" ], 0 ], 40 ]
            ],
            "circle-color": [
               "interpolate", [ "linear" ], [ "get", "brightness" ],
               200, "#fd9e70", 350, "#ff0000"
            ],
            "circle-opacity": 0.75
         }
      }
   },
   {
      "description": "Focos de calor NOAA20_24HRS",
      "name": "NOAA20_24HRS",
      "source": {
         "type": "geojson",
         "data": "https://vectortiles.000webhostapp.com/data/fires_noaa20_24hrs.json"
      },
      "layer": {
         "id": "NOAA20_24HRS",
         "type": "circle",
         "source": "NOAA20_24HRS",
         "layout": {
            "visibility": "none"
         },
         "paint": {
            "circle-radius": [
               "interpolate", [ "linear" ], [ "zoom" ], 4, [ "/", [ "number", [ "get", "brightness" ], 0 ], 80 ],
               15, [ "/", [ "number", [ "get", "brightness" ], 0 ], 40 ]
            ],
            "circle-color": [
               "interpolate", [ "linear" ], [ "get", "brightness" ],
               200, "#fd9e70", 350, "#ff0000"
            ],
            "circle-opacity": 0.75
         }
      }
   },
   {
      "description": "Focos de calor MODIS_24HRS",
      "name": "MODIS_24HRS",
      "source": {
         "type": "geojson",
         "data": "https://vectortiles.000webhostapp.com/data/fires_modis_24hrs.json"
      },
      "layer": {
         "id": "MODIS_24HRS",
         "type": "circle",
         "source": "MODIS_24HRS",
         "layout": {
            "visibility": "none"
         },
         "paint": {
            "circle-radius": [
               "interpolate", [ "linear" ], [ "zoom" ], 4, [ "/", [ "number", [ "get", "brightness" ], 0 ], 80 ],
               15, [ "/", [ "number", [ "get", "brightness" ], 0 ], 40 ]
            ],
            "circle-color": [
               "interpolate", [ "linear" ], [ "get", "brightness" ],
               200, "#fd9e70", 350, "#ff0000"
            ],
            "circle-opacity": 0.75
         }
      }
   }
]
