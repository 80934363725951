{
    "map": {
        "base": "ignBase",
        "item": [
            {
                "style": "esriStyleSatellite",
                "name": "esriSatellite",
                "description": "ESRI Satelite"
            },
            {
                "style": "googleSatellite",
                "name": "googleSatellite",
                "description": "Google Satelite"
            },
            {
                "style": "ignStyleBase",
                "name": "ignBase",
                "description": "IGN"
            },
            {
                "style": "ignStyleGrey",
                "name": "ignGrey",
                "description": "IGN Gris"
            },
            {
                "style": "ignStyleDark",
                "name": "ignDark",
                "description": "IGN Oscuro"
            },
            {
                "style": "esriStyle",
                "name": "esri",
                "description": "ESRI"
            },
        ]
    },
    "layer": {
        "base": "Limiteprovincial_area",
        "item": [
            {
                "name": "Limiteprovincial_area",
                "description": "Límite Provincial",
                "visible": true,
            },
            {
                "name": "hillshade",
                "description": "Elevación",
                "visible": false,
            },
            {
                "name": "Vialidad_lineas",
                "description": "Caminos",
                "visible": false,
            },
            {
                "name": "Bomberos",
                "description": "Bomberos",
                "visible": false,
            },
            {
                "name": "Agua",
                "description": "Otras fuentes de agua",
                "visible": false,
            },           {
                "name": "Hidrografia_lineas",
                "description": "Hidrografia_lineas",
                "visible": false,
            },
            {
                "name": "Hidrografia_areas",
                "description": "Hidrografia_areas",
                "visible": false,
            },
            {
                "name": "Transporte-Aereo",
                "description": "Transporte-Aereo",
                "visible": false,
            },
            {
                "name": "Inventario-Bosque_areas",
                "description": "Inventario-Bosque_areas",
                "visible": false,
            },
            {
                "name": "Vegetacion-Unidades_areas",
                "description": "Vegetacion-Unidades_areas",
                "visible": false,
            },
            {
                "name": "SNPP_24HRS",
                "description": "Focos de calor SNPP_24HRS",
                "visible": true,
            },
            {
                "name": "NOAA20_24HRS",
                "description": "Focos de calor NOAA20_24HRS",
                "visible": true,
            },
            {
                "name": "MODIS_24HRS",
                "description": "Focos de calor MODIS_24HRS",
                "visible": true,
            },
            {
                "name": "ndvi16",
                "description": "NDVI 16 días - Terra/MODIS",
                "visible": true,
            },
            {
                "name": "fwi",
                "description": "Peligro de Incendios Forestales",
                "visible": true,
            }
        ]
    }
}
