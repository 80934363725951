[
    {
        "layer": "Transporte-Aereo",
        "action": "click",
        "lng": "e.lngLat",
        "html": "e.features[0].properties.Tipo + \" (\" +e.features[0].properties.nam +\")\""
    },
    {
        "layer": "Transporte-Aereo",
        "action": "mouseenter",
        "cursor": "pointer"
    },
    {
        "layer": "Transporte-Aereo",
        "action": "mouseleave",
        "cursor": ""
    },
    {
        "layer": "Vialidad_lineas",
        "action": "click",
        "lng": "e.lngLat",
        "html": "e.features[0].properties.fna2 + \" (\" + e.features[0].properties.Tipo + \")\""
    },
    {
        "layer": "Vialidad_lineas",
        "action": "mouseenter",
        "cursor": "pointer"
    },
    {
        "layer": "Vialidad_lineas",
        "action": "mouseleave",
        "cursor": ""
    },
    {
        "layer": "Bomberos",
        "action": "click",
        "lng": "e.lngLat",
        "html": "e.features[0].properties.telefono"
    },
    {
        "layer": "Bomberos",
        "action": "mouseenter",
        "cursor": "pointer"
    },
    {
        "layer": "Bomberos",
        "action": "mouseleave",
        "cursor": ""
    },
    {
        "layer": "Agua",
        "action": "click",
        "lng": "e.lngLat",
        "html": "e.features[0].properties.Tipo"
    },
    {
        "layer": "Agua",
        "action": "mouseenter",
        "cursor": "pointer"
    },
    {
        "layer": "Agua",
        "action": "mouseleave",
        "cursor": ""
    },
    {
        "layer": "Inventario-Bosque_areas",
        "action": "click",
        "lng": "e.lngLat",
        "html": "e.features[0].properties.Tipo"
    },
    {
        "layer": "Inventario-Bosque_areas",
        "action": "mouseenter",
        "cursor": "pointer"
    },
    {
        "layer": "Inventario-Bosque_areas",
        "action": "mouseleave",
        "cursor": ""
    }
]
